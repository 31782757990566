// React
import { useState } from 'react';

// <Switch />
import Switch from "react-switch";

// CSS
import './App.css';

const MODELS = [
  "text-ada-001",
  "text-babbage-001",
  "text-curie-001",
  "text-davinci-003"]

// (GBP) OpenAI API Pricing for GPT-3 Models as of 16/12/2022
const USD_TO_GBP = 0.82;
const COSTS = [
  0.0004,
  0.0005,
  0.0020,
  0.0200].map(val => val * USD_TO_GBP);

const QUALITY = [
  "low",
  "med",
  "high",
  "very-high - same as ChatGPT"];

const constructPrompt = (question, extra, questions, answers) => {
  const max = Math.max(questions.length, answers.length);
  let out = extra + "\n";
  for (let i=0; i<max; i++) {
    out += questions[i] + "\n"
    out += answers[i] + "\n"
  }
  out += question;
  console.log("constructed prompt:", question, questions, answers, out);
  return out;
};

const App = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [estCost, setEstCost] = useState(0);
  const [model, setModel] = useState(0);
  const [question, setQuestion] = useState("");
  const [extra, setExtra] = useState("");
  const [moderate, setModerate] = useState(false);

  const estimateCost = prompt => {
    const len  = prompt.length / 750;
    const cost = COSTS[model] * len;
    console.log("cost calc:", len, COSTS[model], cost)
    return cost;
  };

  const handleQuestion = question => {
    const prompt = constructPrompt(question, extra, questions, answers);
    console.log("constructed prompt:", prompt);
    fetch("/.netlify/functions/gpt", {
      method: 'POST',
      headers: {
        'Accept': "application/json",
        'Content-Type': "application/json"
      },
      body: JSON.stringify({
        "prompt": prompt,
        "model": MODELS[model],
        "moderation": moderate
      })
    })
      .then(res => res.json())
      .then(data => {
        let answer = data["text"];
        console.log("text:", data["text"]);
        if (moderate) {
          if (data["moderation"]) {
            const flagged = data["moderation"]["results"][0]["flagged"];
            if (flagged) {
              const categories = data["moderation"]["results"][0]["categories"];
              const cats       = Object.keys(categories);
              const valid      = cats.filter(key => categories[key]);
              answer = "List of flagged categories (" + valid.join(",") + ")";
            }
          }
        }
        const newQuestions = [...questions, question];
        const newAnswers   = [...answers,   answer];
        const estimatedCost = estimateCost(
          constructPrompt("", extra, newQuestions, newAnswers));
        setQuestions(newQuestions);
        setAnswers(newAnswers);
        setEstCost(estimatedCost);
        setQuestion("");
      });
  };

  const handleRefresh = () => {
    setQuestions([]);
    setAnswers([]);
    setEstCost(0);
    setQuestion("");
  };

  const handleSubmit = ev => {
    // Prevent refresh
    ev.preventDefault();

    console.log("Question:", question);
    handleQuestion(question);
  }

  console.log("estCost:", estCost);
  return (
    <div className="app">
      <h1>ThreatGPT</h1>
      <div>
        <span style={{
          marginRight: "1rem"
        }}>
          Model:
        </span>
        <select
           onChange={e => setModel(MODELS.indexOf(e.target.value))}
        >
          {MODELS.map((model, i) => (
            <option value={model} key={i}>
              {model} ({QUALITY[i]}) - £{Number(COSTS[i]).toFixed(5)}/750 words
            </option>
          ))}
        </select>
        <div style={{
          
        }}>
          <span
            style={{
              marginRight: "1rem"
            }}
          >
            Estimated Cost: £{estCost}
          </span>
          <button
            onClick={handleRefresh}
          >
            Refresh Conversation
          </button>
          <span
            style={{
              marginLeft: "1rem",
              marginRight: "1rem"
            }}
          >
            Moderation
          </span>
          <Switch onChange={setModerate} checked={moderate} />
        </div>
      </div>
      <div>
        <span>Extra Information</span>
        <textarea
          style={{marginLeft: "1rem", marginTop: "1rem"}}
          value={extra}
          onChange={(ev) => setExtra(ev.target.value)}
        />
      </div>
      <div className="qa">
        {[...Array(Math.max(questions.length, answers.length)).fill().keys()].map(i => (
          <div key={i}>
            {(
              <pre className="response">
                <b>You: </b>{questions[i]}
              </pre>
            )}
            {(
              <pre className="response">
                <b>ChatBot: </b>{answers[i]}
              </pre>
            )}
          </div>
        ))}
      </div>
      <form className="question-container" onSubmit={handleSubmit}>
        <input
          type="text"
          value={question}
          onChange={(ev) => setQuestion(ev.target.value)}
          className="question" />
        <input
          type="submit"
          className="question-button"
          value="Ask"
        />      
      </form>
    </div>
  )
}

export default App;